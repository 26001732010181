import React from 'react';
import '../css/Links.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

function MainHdrTop() {
  return (
    <div className="links">
        <div className='header__top'>
          <p className='pe-4'><a href="mailto:bjfindia@gmail.com">bjfindia@gmail.com</a> / <a href="mailto:indiabjf@gmail.com">indiabjf@gmail.com</a></p>
          <p className='pe-4'><a href="tel:01725078997">0172-5078997</a></p>
          <div className='social'>
                <a href="http://facebook.com/bjfindia" target="_blank"><FacebookIcon /></a>
                <a href="https://in.linkedin.com/in/bjf-india-899471126" target="_blank"><LinkedInIcon /></a>
                <a href="https://twitter.com/bjfichd?lang=en" target="_blank"><XIcon /></a>
          </div>
        </div>
    </div>
  )
}

export default MainHdrTop