import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import Login from "./components/Login";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Register from './components/Register';
import Forgot from './components/Forgot';
import Reset from './components/Reset';
import Apply from './components/admissions/Apply';
import Apply1 from './components/admissions/Apply1';
import Apply2 from './components/admissions/Apply2';
import AboutUs from './components/inner-pages/AboutUs';
import AdmissionPage from './components/inner-pages/admission/renderAdmission';
import BJFStars from './components/inner-pages/admission/bjf-stars/renderBJFStars';
import BJFHeadStart from './components/inner-pages/admission/bjf-headstart/renderBJFHeadstart';
import BJFRepeaters from './components/inner-pages/admission/bjf-repeaters/renderBJFRepeaters';
import BJFNavodaya from './components/inner-pages/admission/bjf-navodaya/renderBJFNavodaya';
import BJFPress from './components/inner-pages/media-news/render-press';
import ContactUs from './components/inner-pages/contactUs';
import Donation from './components/inner-pages/donations/renderDonations.js';
import StatutoryInfo from './components/inner-pages/statutory-info/renderStatutoryInfo';
import LifeAtCampus from './components/inner-pages/campus/renderLifeAtCampus';
// import BJFResults from './components/inner-pages/results/renderResults';
import BJFResultsUpdated from './components/inner-pages/results-updated/renderResults';
import BJFWrittenTestRolls2023 from './components/inner-pages/written-test-rolls-2023-25/renders-rolls';
import BJFSampleQsts2023 from './components/inner-pages/sample-qsts-2023-25/renders-questions';
import { AdminHeader } from "./adminComp/adminHeader";
import { OnlineApp } from "./adminComp/onlineApp";
import { OnlineAppEdit } from "./adminComp/onlineAppEdit";
import { UploadedFormEdit } from "./adminComp/uploadedFormEdit";
import { Announcement } from "./adminComp/announcement";
import { EditAnnouncement } from './adminComp/editAnnouncement';
import { AdminMediaList } from './adminComp/media-list';
import { EditMedia } from './adminComp/editMedia';
import { AdminCallBacks } from './adminComp/requestedCallbacks';
import { AdminDashboard } from './adminComp/adminDashboard';

import OTP from './components/OTP'
import { RenderHome } from './components/RenderHome';
import { useDispatch, useSelector } from 'react-redux';
import { WrittenForms } from './adminComp/writtenForms';
import ChangePassword from './components/changePassword';

/* new random anounces */
import ShortlistedInterview from './components/inner-pages/new-announces/shortlisted-for-interview-2023-25';
import InterviewInstructions from './components/inner-pages/new-announces/interview-instructions';
import BJFInterviewMaterials from './components/inner-pages/new-announces/interview-materials/render-interview-materials';
import SelectedCandidates2023To2025 from './components/inner-pages/new-announces/selected-candidates-BJF-Stars-Program-2023-25.js';
import CbseResult2023 from './components/inner-pages/new-announces/bjf-Stars-2023-CBSE-XII-result';
import JeeMainI2024 from './components/inner-pages/new-announces/jee-main-I-2024-result.js';
import BJFSelection2ndRound2024 from './components/inner-pages/new-announces/bjf-stars-second-round-selection-2024-2026.js';

import BJFScreeningTestRolls2025 from './components/inner-pages/screening-test-rolls-2025-27/renders-rolls.js';
import AsalVidya from './components/inner-pages/new-announces/asalVidhya.js';

// import SocialMaterials from './components/inner-pages/new-announces/social-awareness/index.js';
import BJFRepeaters2025 from './components/inner-pages/new-announces/bjf-repeaters-2025.js';
import SocialMaterials2027 from './components/inner-pages/new-announces/social-awareness-2027.js';
import ShortListed2027 from './components/inner-pages/new-announces/shortlisted-for-interview-2027.js';

import { useEffect } from 'react';

function App() {
  const Data=useSelector(state=>state)
  let isSignedIn=Data.auth.isSignedIn;
  console.log("line 41",Data)  
  var home = null
  var header = null 
  let dispatch = useDispatch()
  if(isSignedIn) {
    if( Data.auth.role == 'admin'){
      home = <AdminDashboard/>
      header = <AdminHeader/>
    }
    else if (Data.auth.role == 'service_desk'){
      home  = <AdminCallBacks/>
      header = <AdminHeader/>
    }
    else{
      home = <RenderHome/>
      header = <Header/>
    }
  } else{
    home = <RenderHome/>
    header = <Header/>
  }

  useEffect(() => {
    dispatch({type : "APPLIED_FOR" , payload : null})
  }, [])
  
  
  return (
    <Router forceRefresh>
    <div className="app">

    {header}

      
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={ <Register />} />
        <Route exact path="/forgot-password" element={ <Forgot />} />
        <Route exact path="/reset-password" element={<Reset />} />
        <Route exact path="/otp" element={<OTP/>} />
        <Route exact path="/change-password" element={<ChangePassword/>} />
        <Route exact path="/" element={home} />
        <Route exact path="/AboutUs" element={ <AboutUs/>} />
        <Route exact path="/admission" element={ <AdmissionPage/>} />
        <Route exact path="/admission/bjf_stars" element={ <BJFStars/>} />
        <Route exact path="/admission/bjf_headstart" element={ <BJFHeadStart/>} />
        <Route exact path="/admission/bjf_repeaters" element={ <BJFRepeaters/>} />
        <Route exact path="/admission/bjf_navodaya" element={ <BJFNavodaya/>} />
        <Route exact path="/medias" element={ <BJFPress/>} /> 
        <Route exact path="/contact-us" element={ <ContactUs/>} /> 
        <Route exact path="/donations" element={ <Donation/>} /> 
        <Route exact path="/statutory-info" element={ <StatutoryInfo/>} />  
        <Route exact path="/life-at-campus" element={ <LifeAtCampus/>} />  
        {/* <Route exact path="/results" element={ <BJFResults/>} /> */}
        <Route exact path="/resultss" element={ <BJFResultsUpdated/>} />
        
        <Route exact path="/written-test-rolls-2023-25" element={ <BJFWrittenTestRolls2023/>} />        
        
        <Route exact path="/test-sample-2023-25" element={ <BJFSampleQsts2023/>} />
        <Route exact path="/jee-main-2024" element={ <JeeMainI2024/>} />  
        <Route exact path="/bjf-selection-2nd-round-2024" element={ <BJFSelection2ndRound2024/>} />              
        
        <Route exact path="/admission/apply-online" element={<Apply/>} /> 
        <Route exact path="/admission/apply-online1" element={<Apply1/>} />
        <Route exact path="/admission/apply-online2" element={<Apply2/>} />
        {/* {isSignedIn ? <Route exact path="/admission/apply-online" element={<Apply/>} /> : <Route path="*" element={<Navigate to="/login" replace />}/>} */}

        {/* {isSignedIn ? <Route exact path="/admission/apply-online1" element={<Apply1/>} /> : <Route path="*" element={<Navigate to="/login" replace />}/>} */}

        {/* {isSignedIn ? <Route exact path="/admission/apply-online2" element={<Apply2/>} /> : <Route path="*" element={<Navigate to="/login" replace />}/>} */}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/onlineApp" element={<OnlineApp />} />: <Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/uploadedForm" element={<WrittenForms/>} />:  <Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/viewForm" element={<OnlineAppEdit/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/writtenFormView" element={<UploadedFormEdit/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/adminMedia" element={<AdminMediaList/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/editMedia" element={<EditMedia/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/requested-callbacks" element={<AdminCallBacks/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}
        {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/dashboard1" element={<AdminDashboard/>} />:<Route path="*" element={<Navigate to="/login" replace />}/>}

        
        {/* New Announces */}
        <Route exact path="/interview-shortlist-2023-25" element={ <ShortlistedInterview/>} />
        <Route exact path="/interview-instructions" element={ <InterviewInstructions/>} />
        <Route exact path="/interview-materials" element={ <BJFInterviewMaterials/>} />
        <Route exact path="/selected-candidates-2023-to-2025" element={ <SelectedCandidates2023To2025/>} />
        <Route exact path="/cbse-result-2023" element={ <CbseResult2023/>} />
        <Route exact path="/asal-vidhya" element={ <AsalVidya/>} />   
        <Route exact path="/screening-test-rolls-2025-27" element={ <BJFScreeningTestRolls2025/>} />
        
        {/* <Route exact path="/social-materials" element={ <SocialMaterials/>} /> */}
        <Route exact path="/bjf-repeaters-2025" element={ <BJFRepeaters2025/>} />  
        <Route exact path="/social-materials-2027" element={ <SocialMaterials2027/>} /> 
        <Route exact path="/shortlisted-2027" element={ <ShortListed2027/>} />         
        
        
        {/* {isSignedIn && Data.auth.role == "admin" ?<Route exact path="/announcement" element={<Announcement />} />: <Route path="*" element={<Navigate to="/login" replace />}/>} */}
        <Route exact path="/announcement" element={<Announcement />} />
       
        
       
      </Routes>
      
    </div>
    
    </Router>
  );
}

export default App;
