import React from 'react';
import Footer from '../../Footer';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/pdf/Candidates shortlisted for BJF Stars 2027 Interview v2.0.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function ShortListed2027() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2025-2027: Shortlisted Candidates For Interview</h1>
                        <p className='mb-0'>The students listed below have been shortlisted for the Interview Round, which will begin on 26th March 2025 (Wednesday). The interview dates and times will be posted on the website the next day. Additionally, these details, along with other relevant information, will be also shared with the students personally in the coming days. The interview round will be conducted in the BJFI Campus, SGGS Bhawan, Plot #1, Sector 28-A, Madhya Marg, Chandigarh.</p>                    
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Iframe iframe={fileUrl["fdFile"]} />                        
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default ShortListed2027