import React, { useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DraftsIcon from '@mui/icons-material/Drafts';
import '../css/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  let today = new Date();
  let yearStamp = today.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
 }, []);

  return (
    <div>
      <div className='footMap'>
        <iframe src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.8523074991513!2d76.80473661497524!3d30.72255218164074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fecdf61e406b3%3A0x7cc41a00c6d80096!2sBhai%20Jaitaji%20Foundation%20India!5e0!3m2!1sen!2sin!4v1672988079006!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className='footer'>
        <div className='container-lg'>
          <div className='footerup'>
            <div className='row g-3'>
              <div className='col-sm-12 col-md-6 col-lg-3'>
                <Link to="/">
                  <img className='footerlogo' src='/svg/logo-footer.svg' alt="BJF India" />
                </Link>
                <div className='footersocial'>
                  <a href="http://facebook.com/bjfindia" target="_blank"><FacebookIcon /></a>
                  <a href="https://in.linkedin.com/in/bjf-india-899471126" target="_blank"><LinkedInIcon /></a>
                  <a href="https://twitter.com/bjfichd?lang=en" target="_blank"><XIcon /></a>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3'>
                {/* <div className='d-flex py-2'>
                  <div className='pe-2'><PhoneInTalkIcon /></div>
                  <div>
                    <Link to="javascript:void(0)" onClick={() => window.location = 'tel:1800572000'}>1800-572-000</Link>
                  </div>
                </div> */}
                <div className='d-flex py-2'>
                  <div className='pe-2'><PhoneInTalkIcon /></div>
                  <div>
                    <Link to='javascript:void(0)' onClick={() => window.location = 'tel:01725078997'}>0172-5078997, 5078995</Link>
                  </div>
                </div>
                <div className='d-flex py-2'>
                  <div className='pe-2'><DraftsIcon /></div>
                  <div>
                    <Link to='javascript:void(0)' onClick={() => window.location = 'mailto:bjfindia@gmail.com'}>bjfindia@gmail.com</Link>
                  </div>
                </div>
                <div className='d-flex py-2'>
                  <div className='pe-2'><DraftsIcon /></div>
                  <div>
                    <Link to='javascript:void(0)' onClick={() => window.location = 'mailto:indiabjf@gmail.com'}>indiabjf@gmail.com</Link>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3'>
                <h4 className='mb-3'>Quick Links</h4>
                <Link to="/aboutus" className='btn p-0'>About Us</Link>
                <Link to="/admission" className='btn p-0'>Admissions</Link>
                <Link to="/life-at-campus" className='btn p-0'>Life at Campus</Link>
                <Link to="/resultss" className='btn p-0'>Results</Link>
                <Link to="/medias" className='btn p-0'>Media/News</Link>
                <Link to="/contact-us" className='btn p-0'>Contact Us</Link>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3'>
                <h4 className='mb-3'>About BJFI</h4>
                Bhai Jaitajee Foundation India is an organization in the social entrepreneurship space, and named after a hero from Sikh history.
              </div>
            </div>
          </div>
          <div className='footerdown d-flex justify-content-between'>
            <div>
              <p className='m-0'>Copyright &copy; {yearStamp}, Bhai Jaitajee Foundation India. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer