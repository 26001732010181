import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from '../../Footer';
import Amritsar from './amritsar';
// import Chandigarh from './chandigarh';
import Faridkot from './faridkot';
import Hoshiarpur from './hoshiarpur';
import Instructions from './instructions';
import Ludhiana from './ludhiana';
import Mansa from './mansa';
import Patiala from './patiala';
import RampuraPhul from './rampura-phul';
import Mohali from './mohali';

function BJFScreeningTestRolls2025() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2027 Screening Test Roll Numbers &amp; Instructions</h1>
                        <p className='mb-2'>The second List of Roll Numbers.</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Tab.Container id="genSideNav" defaultActiveKey="01">
                            <div className='row g-0 normVTabs'>
                                <div className='col-12 col-md-3'>
                                    <div className='pe-md-4 h-100'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item><Nav.Link eventKey="01">Amritsar</Nav.Link></Nav.Item>
                                            {/* <Nav.Item><Nav.Link eventKey="02">Chandigarh</Nav.Link></Nav.Item> */}
                                            <Nav.Item><Nav.Link eventKey="03">Faridkot</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="04">Hoshiarpur</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="05">Ludhiana</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="06">Mansa</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="07">Mohali</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="08">Patiala</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="09">Rampura Phul</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="10">Instructions</Nav.Link></Nav.Item>                                            
                                        </Nav>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 pe-2'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="01">
                                            <Amritsar />
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="02">
                                            <Chandigarh/>
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="03">
                                            <Faridkot />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="04">
                                            <Hoshiarpur />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="05">
                                            <Ludhiana />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="06">
                                            <Mansa />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="07">
                                            <Mohali/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="08">
                                            <Patiala />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="09">
                                            <RampuraPhul />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="10">
                                            <Instructions />
                                        </Tab.Pane>
                                        
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFScreeningTestRolls2025